import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { useAppSelector } from 'redux/toolkit/hooks'

export enum TabName {
  preview,
  source
}

export interface State {
  selectedTabName: TabName
  isPreviewEnabled: boolean
  isBlockedQuarEnabled: boolean
}

export interface EventHandlers {
  onTabChange: (_: ChangeEvent<Record<string, never>>, value: TabName) => void
}

export type MessageDetailsContentTabsLogic = [State, EventHandlers]

export const useMessageDetailsContentTabsLogic = (): MessageDetailsContentTabsLogic => {
  const { message } = useAppSelector(_store => ({
    message: _store.mstore.message
  }))
  const { isMessagePreviewAvailableHelpdesk } = useMessageLogRights()
  const [selectedTabName, setSelectedTabName] = useState<TabName>(
    isMessagePreviewAvailableHelpdesk ? TabName.preview : TabName.source
  )

  const onTabChange = useCallback((_: ChangeEvent<Record<string, never>>, value: TabName) => {
    setSelectedTabName(value)
  }, [])
  return useMemo(
    () => [
      {
        selectedTabName,
        isPreviewEnabled: isMessagePreviewAvailableHelpdesk,
        isBlockedQuarEnabled: !!message?.allowMsgBody
      },
      {
        onTabChange
      }
    ],
    [onTabChange, selectedTabName, isMessagePreviewAvailableHelpdesk, message]
  )
}
